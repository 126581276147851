import {Component, OnInit, ViewChild} from '@angular/core';

import {IonContent, IonRouterOutlet, ModalController, Platform} from '@ionic/angular';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {Capacitor} from '@capacitor/core';
import {Store} from '@ngrx/store';
import {State} from './reducers';
import {UserAuthStateChanged} from './actions/user.actions';
import firebase from 'firebase/compat/app';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Hydrate, Initialize, OpenUrl} from './actions/app.actions';
import {updateMomentCalendar} from './utils/DateTimeUtils';
import {environment} from '../environments/environment';
import {
    changeToDarkStatusBarColor,
    onDidDismissModalDarkBackground,
    presentModalDarkBackground
} from './utils/StatusBarUtils';
import {StatusBar} from '@capacitor/status-bar';
import {SplashScreen} from '@capacitor/splash-screen';
import {App} from '@capacitor/app';
import {FirebaseAnalytics} from '@capacitor-community/firebase-analytics';
import {recordException} from './utils/app.utils';
import {Keyboard} from "@capacitor/keyboard";
import {filter, map, switchMap} from "rxjs/operators";
import {connectAuthEmulator, getAuth} from "@angular/fire/auth";
import {fromFirebaseUser} from "./models/user";
import {GoogleAuth} from "@codetrix-studio/capacitor-google-auth";
import {register as registerSwiper} from "swiper/element/bundle";
import {activeProviderSlug} from "./services/provider-services.service";
import {LoginPageComponent} from "./login-page/login-page.component";
import {SLUG_KEY_ROUTE} from "./utils/routes.utils";

registerSwiper();

declare var window: any;

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

    @ViewChild(IonContent, { read: IonContent }) content: IonContent;
    @ViewChild(IonRouterOutlet, { static : true }) routerOutlet: IonRouterOutlet;

    private static scriptLoaded = false;
    public isMobile: boolean;
    public isNative = Capacitor.isNativePlatform();
    public authStateLoaded = false;

    constructor(
        private platform: Platform,
        private afAuth: AngularFireAuth,
        private store$: Store<State>,
        private router: Router,
        private modalController: ModalController,
        private route: ActivatedRoute,
    ) {
        this.isMobile = !this.platform.is('desktop');
        this.initializeApp();
    }

    ngOnInit() {

        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                switchMap((e) => {
                    let currentRoute = this.route.root;

                    while (currentRoute.children.length > 0) {
                        currentRoute = currentRoute.children[0];
                    }

                    return currentRoute.paramMap.pipe(
                        map(params => ({params, route: (e as {url?: string}).url}))
                    );
                })
            )
            .subscribe(({params, route}) => {
                const slug = params.get(SLUG_KEY_ROUTE) ?? environment.defaultProviderSlug;
                const activeProvider = activeProviderSlug();
                if (slug && slug !== activeProvider) {
                    activeProviderSlug(slug);
                }

                // const rootPath = getRootPathArr();
                // const currentRouteArr = route
                //     ?.split("/")
                //     ?.filter((r: string) => !!r)
                //
                // const isRootPath = isEqual(currentRouteArr, rootPath);
                // this.hideHeader = !isRootPath;
                // const oneOfTabs = currentRouteArr?.length === 1
                //     && (currentRouteArr[0] === HOME_ROUTE
                //         || currentRouteArr[0] === RESERVATIONS_ROUTE
                //         || currentRouteArr[0] === 'profile');
                // this.showBackButton = !(isRootPath || currentRouteArr?.length === 0 || oneOfTabs);

                // this.onScroll();

                // setTimeout(() => {
                //     const header = document.querySelector('ion-header');
                //     const content = document.querySelector('ion-content');
                //     if (header && content) {
                //         // 16px is the default margin
                //         const navbarHeight = header.scrollHeight + 16;
                //         content.style.setProperty('--padding-top', `${navbarHeight}px`);
                //     }
                // }, 500);
            });
    }

    initializeApp() {
        console.log('initialize app: ' + `local=${environment.local}, prd=${environment.production}`);
        updateMomentCalendar();

        if (environment.local) {
            connectAuthEmulator(getAuth(), "http://0.0.0.0:9099");
        }

        this.platform.ready().then(() => {
            this.store$.dispatch(new Initialize());

            if (!AppComponent.scriptLoaded) {
                this.loadScript(environment.firebase.apiKey);
                AppComponent.scriptLoaded = true;
            }

            AppComponent.initializeSignInWithGoogle();

            console.log('router: ', this.router.url);
            App.addListener('appUrlOpen', data => {
                if (data && data.url) {
                    console.log(`url: ${data.url}`);
                    this.store$.dispatch(new OpenUrl(data.url));
                    console.log('router: ', this.router.url);
                    console.log('firebase dynamic link');
                }
            });
            if (this.platform.is('desktop')) {
                // Using community firebase analytics instead of angularfire version because it has issues
                // with capacitor's iOS custom scheme
                FirebaseAnalytics.initializeFirebase(environment.firebase).catch(e => {
                    console.error('Error initializing firebase analytics', e);
                    recordException('Error initializing firebase analytics');
                    recordException(e);
                });

                if (window.location.href) {
                    console.log(`url: ${window.location.href}`);
                    this.store$.dispatch(new OpenUrl(window.location.href));
                }
            }
            this.store$.dispatch(new Hydrate());
            this.afAuth.authState.subscribe((user: firebase.User | null) => {
                this.authStateLoaded = true;
                console.log(`user auth changed: loggedIn=${!!user}`);
                this.store$.dispatch(new UserAuthStateChanged(fromFirebaseUser(user)));
                SplashScreen.hide().then(() => {
                    if (Capacitor.isPluginAvailable('StatusBar')) {
                        StatusBar.show()
                            .then(() => changeToDarkStatusBarColor())
                            .catch(e => console.error('error settings status bar color', e));
                    }
                }).catch(e => console.error("error hiding splash screen", e));
            });

            if (this.platform.is('ios')) {
                Keyboard.addListener('keyboardWillShow', info => {
                    const content: NodeListOf<HTMLElement> = document.querySelectorAll('ion-content');
                    if (content.length > 0) {
                        content[content.length - 1].style.setProperty('--keyboard-offset', `${info.keyboardHeight}px`);
                    }
                })

                Keyboard.addListener('keyboardWillHide', () => {
                    const content: NodeListOf<HTMLElement> = document.querySelectorAll('ion-content');
                    if (content.length > 0) {
                        content[content.length - 1].style.removeProperty('--keyboard-offset');
                    }
                })
            }
        });
    }

    loadScript(key: string) {
        const node = document.createElement('script'); // creates the script tag
        // sets the source (insert url in between quotes)
        node.src = 'https://maps.googleapis.com/maps/api/js?key=' + key + '&v=3.exp&libraries=places,geometry';
        node.type = 'text/javascript'; // set the script type
        node.async = true; // makes script run asynchronously
        node.charset = 'utf-8';
        // append to head of document
        document.getElementsByTagName('head')[0].appendChild(node);
    }

    private static initializeSignInWithGoogle() {
        if (Capacitor.isNativePlatform()) {
            GoogleAuth.initialize();
        }
    }

    async openLoginPane() {
        const modal = await this.modalController.create({
            component: LoginPageComponent,
            swipeToClose: true,
            presentingElement: this.routerOutlet?.nativeEl
        });
        onDidDismissModalDarkBackground(modal);
        return presentModalDarkBackground(modal).then(() => modal);
    }

    // @HostListener('ionScroll', ['$event'])
    // onScroll($event?: any) {
    //     const scrollElement = $event?.detail;
    //     const scrollTop = scrollElement?.scrollTop ?? 0;
    //     this.headerHiddenOnScroll = scrollTop > this.lastScrollTop;
    //     this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
    //     console.log(`headerHidden: ${this.headerHiddenOnScroll}`);
    // }
}
