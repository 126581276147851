import {AppEffects} from './app.effects';
import {UserEffects} from './user.effects';
import {NotificationsEffects} from './notifications.effects';
import {DiscountEffects} from './discount.effects';
import {AnalyticsEffects} from './analytics.effects';
import {WashServicesEffects} from "./wash-services.effects";
import {MarketingEffects} from "./marketing.effects";

export const appEffects = [
    AppEffects,
    UserEffects,
    NotificationsEffects,
    DiscountEffects,
    AnalyticsEffects,
    WashServicesEffects,
    MarketingEffects
];
