<div>
    <h3 class="gb-large-text-size" [class.gb-section-title]="useGlobalTitleStyle"
        [class.title]="!useGlobalTitleStyle" *ngIf="title">
        {{title}}
    </h3>
    <ion-grid *ngIf="grid && grid.length; else noItems">
        <ion-row *ngFor="let col of grid">
            <ion-col *ngFor="let item of col" [size]="col.length > 1 ? 6 : 12">
                <ion-button size="block" (click)="itemSelected.emit(item.key)"
                            class="gb-small-text-size grid-button"
                            fill="outline"
                            [class.selected-button]="selectedItem === item.key">
                    {{item.text}}
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
    <ng-template #noItems>
        <ion-item class="gb-no-border" lines="none" *ngIf="noItemsText">
            <ion-label>{{noItemsText}}</ion-label>
        </ion-item>
    </ng-template>
</div>
