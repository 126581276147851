import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UserLocation} from "../gql/generated/graphql-schema";
import {OrderVehicleSchema} from "../services/vehicles.service";

@Component({
    selector: 'app-search-input',
    templateUrl: './search-input.component.html',
    styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent {
    @Input() vehicle: OrderVehicleSchema;
    @Input() orderLocation?: UserLocation;
    @Input() collapsed = false;
    @Input() loadingLocation: boolean;
    @Input() loadingVehicle: boolean;
    @Input() halfHorizontalMargin = false;

    @Output() searchInputSelected = new EventEmitter();
    @Output() vehicleInputSelected = new EventEmitter();
}
