<div class="profile-items">
    <div *ngFor="let item of items">
        <ion-item *ngIf="item.onlyShowWhenLoggedIn === undefined ? true : (item.onlyShowWhenLoggedIn ? !!user?.uid : !user?.uid)"
                button="true"
                (click)="item.action()"
                lines="full"
                [detail]="showDetailIcon">

            <ion-icon [name]="item.icon" slot="start"></ion-icon>
            <ion-label>
                {{item.label}}
            </ion-label>
        </ion-item>
    </div>

    <div *ngIf="{value: loading$ | async} as loading">
        <ion-item button="true"
                  [detail]="showDetailIcon"
                  (click)="logout()"
                  lines="full"
                  class="gb-transparent"
                  *ngIf="!!user?.uid"
                  [disabled]="loading.value">
            <ion-spinner name="dots"
                         duration="undefined"
                         class="ion-text-center"
                         style="width: 100%"
                         *ngIf="loading.value; else loaded"></ion-spinner>
            <ng-template #loaded>
                <ion-icon name="log-out-outline" slot="start"></ion-icon>
                <ion-label>
                    Logout
                </ion-label>
            </ng-template>
        </ion-item>
    </div>
</div>
