import {Pipe, PipeTransform} from '@angular/core';
import {weekday} from '../utils/DateTimeUtils';
import moment from 'moment';
import {RequestItemDetails, TimeSlot} from '@gobubbleapp/models';
import {TimeSlotPropsFragment} from "../gql/time-slots.graphql-gen";
import {isEqualTimeSlots} from "../utils/reservation.utils";

@Pipe({
    name: 'getWeekday'
})
export class GetWeekdayPipe implements PipeTransform {
    transform(startTime: number): string | null {
        return weekday[new Date(startTime * 1000).getDay()];
    }
}

@Pipe({
    name: 'getMinutesAndSeconds'
})
export class GetMinutesAndSecondsPipe implements PipeTransform {
    transform(timestamp: number): string {
        const time = moment(timestamp * 1000).diff(moment(new Date()));
        const minutes = Math.floor(time / 60);
        const seconds = time - minutes * 60;
        return `${minutes} minutes and ${seconds} seconds`;
    }
}

@Pipe({
    name: 'getHoursAndMinutes'
})
export class GetHoursAndMinutes implements PipeTransform {
    transform(totalMinutes: number): string {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        if (hours === 0) {
            return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
        }
        if (minutes === 0) {
            return `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
        }
        return `${hours} ${hours === 1 ? 'hr' : 'hrs'} and ${minutes} ${minutes === 1 ? 'min' : 'mins'}`;
    }
}

@Pipe({
    name: 'fromNowHoursMins'
})
export class FromNowHoursMinsPipe implements PipeTransform {
    transform(epochSec: number): any {
        const end = moment(epochSec);
        const now = moment.now();
        const duration = moment.duration(end.diff(now));

        // Get hours and subtract from duration
        const hours = duration.hours();
        duration.subtract(moment.duration(hours, 'hours'));

        // Get Minutes and subtract from duration
        const minutes = duration.minutes();
        duration.subtract(moment.duration(minutes, 'minutes'));

        if (hours === 0) {
            return `${minutes} mins`;
        } else if (hours === 1) {
            return `${hours} hr ${minutes} mins`;
        } else {
            return `${hours} hrs ${minutes} mins`;
        }
    }
}

@Pipe({
    name: 'reservationDuration'
})
export class ReservationDurationPipe implements PipeTransform {
    transform(details: RequestItemDetails): number {
        const washTypeDuration = details.wash_type.plan && details.wash_type.plan.duration_min ? details.wash_type.plan.duration_min : 0;
        return (details.add_ons || [])
            .map(a => a.plan && a.plan.duration_min ? a.plan.duration_min : 0)
            .reduce((p, c) => p + c, washTypeDuration);
    }
}

@Pipe({
    name: 'toTimeSlotText'
})
export class ToTimeSlotText implements PipeTransform {
    transform(timeSlot: TimeSlot): string {
        return moment(timeSlot.start_time * 1000).format('ddd MMM Do h:mm A')
            + ' - '
            + moment(timeSlot.end_time * 1000).format('h:mm A')
    }
}

@Pipe({
    name: 'epochToDateText'
})
export class EpochToDateTextPipe implements PipeTransform {
    transform(epoch: number): string {
        return moment(epoch * 1000).format('MMM Do')
    }
}

@Pipe({
    name: 'epochToFullDateTimeText'
})
export class EpochToFullDateTimeTextPipe implements PipeTransform {
    transform(epoch: number): string {
        return moment(epoch * 1000).format('MMM Do, yyyy h:mm A')
    }
}

@Pipe({
    name: 'isEqualTimeSlots'
})
export class IsEqualTimeSlotsPipe implements PipeTransform {
    transform(timeSlot1: TimeSlotPropsFragment, timeSlot2: TimeSlotPropsFragment, onlyTimes = false): boolean {
        return isEqualTimeSlots(timeSlot1, timeSlot2, onlyTimes);
    }
}
