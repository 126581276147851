import * as Types from './generated/graphql-schema';

import { gql } from 'apollo-angular';
export type TimeSlotPropsFragment = { __typename?: 'TimeSlot', start_time?: number | null, end_time?: number | null, time_zone_name?: string | null, time_zone_id?: string | null, raw_offset?: number | null, dst_offset?: number | null };

export const TimeSlotPropsFragmentDoc = gql`
    fragment TimeSlotProps on TimeSlot {
  start_time
  end_time
  time_zone_name
  time_zone_id
  raw_offset
  dst_offset
}
    `;