<div class="ion-margin-bottom" [class.list-border-top]="listBorderTop">
    <div class="title-row">
        <ion-label class="title" *ngIf="title">
            {{title}}
        </ion-label>
        <ion-label class="subtitle" *ngIf="subtitle">
            {{subtitle}}
        </ion-label>
    </div>
    <div *ngIf="items && items.length; else noItems" class="items-list">
        <div
                class="item-card"
                *ngFor="let item of items | orderBy: ['-disabled', '-display_order'] | gbReverse"
                [class.selected-item]="item.formControl?.value === item.key"
                [class.item-border]="itemBorder"
                [class.full-width]="item.more_options.length"
                [class.required]="item.option_selection_required && item.more_options?.length > 1 && ((item.formControl?.errors | values)?.length ?? 0) > 0"
        >
            <div class="item-card-content">
                <div class="item-card-row">
                    <ion-icon *ngIf="item.icon" class="item-icon" [name]="item.icon"></ion-icon>
                    <ion-label color="medium" class="ion-text-wrap text-column">
                        <ion-label class="item-title">
                            {{item.text}}
                        </ion-label>
                        <p *ngIf="item.subtitle" class="item-subtitle">
                            {{item.subtitle}}
                        </p>
                    </ion-label>
                </div>
                <div class="item-card-row ion-align-items-end">
                    <ion-icon *ngIf="item.additional_information as info"
                              class="additional-info-icon" name="information-circle-outline"
                              (click)="additionalInfoPopoverContent = info"></ion-icon>
                    <ion-label *ngIf="item.option_selection_required && item.more_options?.length > 1"
                               class="gb-xsmall-text-size ion-padding-top"
                               color="danger">
                        *Required
                    </ion-label>
                </div>
<!--                <ion-icon color="primary" class="checkmark-icon"-->
<!--                          [class.hidden]="!selectedItems[item.key]"-->
                <!--                          name="checkmark-outline">-->
                <!--                </ion-icon>-->
            </div>
            <div
                    class="item-card-buttons"
                    *ngIf="{value: item.more_options && item.more_options.length ? (item.more_options | every: disabledOption) : item.disabled} as disabledItem">
                <ion-button class="select-button" [fill]="item.formControl?.value === item.key ? 'solid' : 'outline'"
                            [disabled]="disabledItem.value"
                            *ngIf="!item.more_options.length"
                            (click)="item.formControl?.setValue(item.key)">
<!--                    <ion-icon *ngIf="selectedItems.get(item.key) || disabledItem.value" slot="start" name="checkmark-outline"></ion-icon>-->
                    <ion-label>
                        {{item.formControl?.value === item.key ? SELECTED_TEXT : SELECT_TEXT}}
                        <span *ngIf="item.buttonTextSuffix && !disabledItem.value" class="gb-xsmall-text-size">
                            {{item.buttonTextSuffix}}
                        </span>
                    </ion-label>
                </ion-button>
                <div *ngIf="item.more_options && item.more_options.length" class="row more-options-row">
                    <ion-button
                            class="select-button"
                            *ngFor="let addOption of item.more_options"
                            [fill]="item.formControl?.value === addOption.key ? 'solid' : 'outline'"
                            (click)="onItemSelected(item.key, addOption.key)"
                            [disabled]="addOption.disabled"
                    >
                        <div class="option-button-text">
<!--                            <ion-icon *ngIf="selectedItems.get(item.key) === addOption.key && !addOption.disabled" slot="start" name="checkmark-outline"></ion-icon>-->
                            <ion-label>
                                {{addOption?.value || (item.formControl?.value === addOption.key ? SELECTED_TEXT : SELECT_TEXT)}}
                                <span *ngIf="addOption.valueSuffix" class="gb-xsmall-text-size">
                                    {{addOption.valueSuffix}}
                                </span>
                            </ion-label>
                        </div>
                    </ion-button>
                </div>
            </div>
<!--            <ion-segment *ngIf="item.more_options && item.more_options.length" class="more-options-row"-->
<!--                         [value]="selectedOption[item.key] || ''" (ionChange)="optionChanged(item.key, $event)">-->
<!--                <ion-segment-button [value]="option.key" *ngFor="let option of item.more_options"-->
<!--                                    [disabled]="(item.more_options | filterBy: ['key']: option.key)[0].disabled">-->
<!--                    <ion-label>{{option.value}}</ion-label>-->
<!--&lt;!&ndash;                    <ion-label *ngIf="disabledText" class="disabled-text">&ndash;&gt;-->
<!--&lt;!&ndash;                        {{disabledText}}&ndash;&gt;-->
<!--&lt;!&ndash;                    </ion-label>&ndash;&gt;-->
<!--                </ion-segment-button>-->
<!--            </ion-segment>-->
<!--            <div class="row disabled-text" *ngIf="isDisabled(item) && disabledText">-->
<!--                <ion-label>-->
<!--                    {{disabledText}}-->
<!--                </ion-label>-->
<!--            </div>-->
        </div>
        <ion-item class="gb-transparent add-item" lines="none" (click)="newItemSelected()" *ngIf="showAddNewItem">
            <ion-icon slot="start" name="add-outline" color="medium"></ion-icon>
            <ion-label color="medium">
                Add
            </ion-label>
        </ion-item>
    </div>
    <ng-template #noItems>
        <ion-item class="gb-no-border" lines="none" *ngIf="noItemsText">
            <ion-label>{{noItemsText}}</ion-label>
        </ion-item>
    </ng-template>
</div>
<ion-popover show-backdrop="true"
             size="cover"
             [isOpen]="!!additionalInfoPopoverContent"
             (ionPopoverDidDismiss)="this.additionalInfoPopoverContent = undefined">
    <ng-template>
        <ion-label class="additional-info-text">
            {{additionalInfoPopoverContent}}
        </ion-label>
    </ng-template>
</ion-popover>
