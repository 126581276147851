import {Action, props} from '@ngrx/store';
import {PushNotificationSchema} from '@capacitor/push-notifications';
import {nanoid} from "nanoid";
import {createGbAction} from "@gobubbleapp/common-ui";

export enum ActionTypes {
    INITIALIZED_PUSH_NOTIFICATIONS = '[Notifications] Initialized push notifications',
    SETUP_PUSH_NOTIFICATIONS = '[Notifications] Setup push notifications',
    REGISTER_FOR_PUSH_NOTIFICATIONS = '[Notifications] Register for push notifications',
    HANDLE_NOTIFICATION = '[Notifications] Handle notifications',
    SHOW_REQUEST_STATUS_FROM_NOTIFICATION = '[Notifications] Handle stationary request notification',
    SHOW_IN_APP_NOTIFICATIONS = '[Notifications] Show in app notifications',
    RESET_PUSH_NOTIFICATIONS_STATE = '[Notifications] reset'
}

export class SetupPushNotifications implements Action {
    readonly type = ActionTypes.SETUP_PUSH_NOTIFICATIONS;

    constructor(public correlationId: string = nanoid()) {
    }
}

export class RegisterForPushNotifications implements Action {
    readonly type = ActionTypes.REGISTER_FOR_PUSH_NOTIFICATIONS;
}

export class HandleNotification implements Action {
    constructor(public notification: PushNotificationSchema, public correlationId: string = nanoid()) {
    }

    readonly type = ActionTypes.HANDLE_NOTIFICATION;
}

export class ShowRequestStatusFromNotification implements Action {
    constructor(public requestId: string, public showChat: boolean = false) {

    }

    readonly type = ActionTypes.SHOW_REQUEST_STATUS_FROM_NOTIFICATION;
}

export class InitializedPushNotifications implements Action {
    readonly type = ActionTypes.INITIALIZED_PUSH_NOTIFICATIONS;
}

export class ShowInAppNotification implements Action {
    readonly type = ActionTypes.SHOW_IN_APP_NOTIFICATIONS;

    constructor(public notification: PushNotificationSchema) {
    }
}

export class ResetPushNotificationsState implements Action {
    readonly type = ActionTypes.RESET_PUSH_NOTIFICATIONS_STATE;

    constructor(public correlationId: string) {
    }
}

export type ActionsUnion =
    HandleNotification |
    ShowRequestStatusFromNotification |
    SetupPushNotifications |
    RegisterForPushNotifications |
    InitializedPushNotifications |
    ShowInAppNotification |
    ResetPushNotificationsState
    ;
