import * as Types from './generated/graphql-schema';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type VehiclePropsFragment = { __typename?: 'Vehicle', id: string, year: string, make: string, model: string, license_plate_state: string, license_plate: string, size: Types.VehicleSize };

export type UserVehiclesGetQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserVehiclesGetQuery = { __typename?: 'Query', vehicles?: Array<{ __typename?: 'Vehicle', id: string, year: string, make: string, model: string, license_plate_state: string, license_plate: string, size: Types.VehicleSize }> | null };

export type UserVehicleDeleteMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type UserVehicleDeleteMutation = { __typename?: 'Mutation', vehicleDelete: { __typename?: 'VehicleDeleteResponse', deleted: boolean, message?: string | null } };

export type UserLocationFragmentFragment = { __typename?: 'UserLocation', id?: string | null, name?: string | null, address?: string | null, latitude?: number | null, longitude?: number | null, additional_instructions?: string | null, access_to_power?: boolean | null, access_to_water?: boolean | null, location_type?: string | null };

export type GeoLocationFragment = { __typename?: 'GeoLocationResponse', latitude?: number | null, longitude?: number | null, supported?: boolean | null, region?: string | null, subdivision?: string | null, city?: string | null };

export type UserLocationsGetQueryVariables = Types.Exact<{
  userLat?: Types.InputMaybe<Types.Scalars['Float']>;
  userLng?: Types.InputMaybe<Types.Scalars['Float']>;
}>;


export type UserLocationsGetQuery = { __typename?: 'Query', deviceLocation?: { __typename?: 'UserLocation', latitude?: number | null, longitude?: number | null, address?: string | null } | null, userLocations?: Array<{ __typename?: 'UserLocation', id?: string | null, name?: string | null, address?: string | null, latitude?: number | null, longitude?: number | null, additional_instructions?: string | null, access_to_power?: boolean | null, access_to_water?: boolean | null, location_type?: string | null }> | null, geoLocation?: { __typename?: 'GeoLocationResponse', latitude?: number | null, longitude?: number | null, supported?: boolean | null, region?: string | null, subdivision?: string | null, city?: string | null } | null };

export type UserLocationSaveMutationVariables = Types.Exact<{
  location: Types.SaveUserLocationInput;
}>;


export type UserLocationSaveMutation = { __typename?: 'Mutation', userLocationSave?: { __typename?: 'UserLocation', id?: string | null, name?: string | null, address?: string | null, latitude?: number | null, longitude?: number | null, additional_instructions?: string | null, access_to_power?: boolean | null, access_to_water?: boolean | null, location_type?: string | null } | null };

export const VehiclePropsFragmentDoc = gql`
    fragment VehicleProps on Vehicle {
  id
  year
  make
  model
  license_plate_state
  license_plate
  size
}
    `;
export const UserLocationFragmentFragmentDoc = gql`
    fragment UserLocationFragment on UserLocation {
  id
  name
  address
  latitude
  longitude
  additional_instructions
  access_to_power
  access_to_water
  location_type
}
    `;
export const GeoLocationFragmentDoc = gql`
    fragment GeoLocation on GeoLocationResponse {
  latitude
  longitude
  supported
  region
  subdivision
  city
}
    `;
export const UserVehiclesGetDocument = gql`
    query UserVehiclesGet {
  vehicles {
    id
    ...VehicleProps
  }
}
    ${VehiclePropsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UserVehiclesGetGQL extends Apollo.Query<UserVehiclesGetQuery, UserVehiclesGetQueryVariables> {
    document = UserVehiclesGetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserVehicleDeleteDocument = gql`
    mutation UserVehicleDelete($id: String!) {
  vehicleDelete(id: $id) {
    deleted
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserVehicleDeleteGQL extends Apollo.Mutation<UserVehicleDeleteMutation, UserVehicleDeleteMutationVariables> {
    document = UserVehicleDeleteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserLocationsGetDocument = gql`
    query UserLocationsGet($userLat: Float, $userLng: Float) {
  deviceLocation @client {
    latitude @export(as: "userLat")
    longitude @export(as: "userLng")
    address
  }
  userLocations {
    ...UserLocationFragment
  }
  geoLocation(input: {latitude: $userLat, longitude: $userLng}) {
    ...GeoLocation
  }
}
    ${UserLocationFragmentFragmentDoc}
${GeoLocationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UserLocationsGetGQL extends Apollo.Query<UserLocationsGetQuery, UserLocationsGetQueryVariables> {
    document = UserLocationsGetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserLocationSaveDocument = gql`
    mutation UserLocationSave($location: SaveUserLocationInput!) {
  userLocationSave(location: $location) {
    ...UserLocationFragment
  }
}
    ${UserLocationFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UserLocationSaveGQL extends Apollo.Mutation<UserLocationSaveMutation, UserLocationSaveMutationVariables> {
    document = UserLocationSaveDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }