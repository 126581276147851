import {Pipe, PipeTransform} from '@angular/core';
import {Vehicle} from '../models/vehicle';

@Pipe({
    name: 'vehicleFromId'
})
export class VehiclePipe implements PipeTransform {
    transform(vehicles: Vehicle[], byVehicleId: string): Vehicle | null {
        const vehicle = vehicles.filter(x => x.id === byVehicleId);
        return vehicle.length > 0 ? vehicle[0] : null;
    }
}

@Pipe({
    name: 'vehicleFromIds'
})
export class VehiclesPipe implements PipeTransform {
    transform(vehicles: Vehicle[], byVehicleIds: string[]): Vehicle[] {
        return vehicles.filter(x => byVehicleIds.indexOf(x.id) > -1);
    }
}
