import {NgModule} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {MomentModule} from 'ngx-moment';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
    GoogleMapsUrlFromLocationPipe,
    GratuityItemPipe, IsValidReservationForSchedulePipe,
    ReservationStatusToFriendlyTextPipe
} from '../pipes/reservation.pipes';
import {NgArrayPipesModule, NgObjectPipesModule} from 'ngx-pipes';
import {NumbersModule} from './numbers.module';
import {DateTimeModule} from './date-time-modules';
import {GbArrayPipesModule} from "./array-pipes.module";

@NgModule({
    imports: [
        IonicModule,
        MomentModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgArrayPipesModule,
        NgObjectPipesModule,
        NumbersModule,
        DateTimeModule,
        GbArrayPipesModule
    ],
    declarations: [
        GoogleMapsUrlFromLocationPipe,
        GratuityItemPipe,
        ReservationStatusToFriendlyTextPipe,
        IsValidReservationForSchedulePipe
    ],
    exports: [
        GoogleMapsUrlFromLocationPipe,
        GratuityItemPipe,
        ReservationStatusToFriendlyTextPipe,
        IsValidReservationForSchedulePipe
    ]
})
export class ReservationPipesModule { }
