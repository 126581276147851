<ion-header>
    <ion-toolbar class="gb-top-toolbar">
        <ion-buttons slot="start">
            <ion-button (click)="close()">
                <ion-icon slot="icon-only" color="dark" name="close-outline"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>
    <div class="container">
        <div class="header">
            <ion-label class="gb-page-title">
                New Vehicle
            </ion-label>
            <div class="circular-segment-container centered">
                <ion-segment class="circular-segment"
                             [(ngModel)]="visibleForm"
                             (ionChange)="updateLookupMethod($event)">
                    <ion-segment-button class="circular-segment-button" value="plate_lookup">
                        <ion-label>
                            Find by license plate
                        </ion-label>
                    </ion-segment-button>
                    <ion-segment-button class="circular-segment-button" value="manual_entry">
                        <ion-label>
                            Pick make and model
                        </ion-label>
                    </ion-segment-button>
                </ion-segment>
            </div>
        </div>
        <form [formGroup]="vehicleFormGroup" class="new-vehicle-form"
              (ngSubmit)="vehicleFormSubmission()"
              *ngIf="{selection: vehicleSelections$ | async} as state">

            <ion-radio-group class="plate-status-radio-group"
                             [formControlName]="vehicleFormControls.LICENSE_PLATE_TYPE_SELECTION_CONTROL"
                             (ionChange)="checkForVehicleFormValidators()"
                             *ngIf="visibleForm !== 'plate_lookup'">
                <ion-item class="gb-transparent" lines="none">
                    <ion-radio slot="start"
                               [value]="plateStatusType.TEMP_PLATE" mode="md"></ion-radio>
                    <ion-label>Temporary Plate?</ion-label>
                </ion-item>
                <ion-item class="gb-transparent" lines="none">
                    <ion-radio slot="start" mode="md"
                               [value]="plateStatusType.NO_PLATE"></ion-radio>
                    <ion-label>No Plate?</ion-label>
                </ion-item>
            </ion-radio-group>

            <div class="license-field-inputs"
                 *ngIf="this.plateStatus !== plateStatusType.NO_PLATE; else noPlateSelected">

                <div class="state-field">
                    <ion-input placeholder="State"
                               readonly="only"
                               [formControlName]="vehicleFormControls.LICENSE_PLATE_STATE_CONTROL"
                               class="vehicle-input state-input input-with-border readonly"
                               (click)="showStatesPicker()"></ion-input>
                </div>
                <div class="license-plate">
                    <ion-input placeholder="License Plate #" maxlength="7"
                               [formControlName]="vehicleFormControls.LICENSE_PLATE_CONTROL"
                               class="vehicle-input input-with-border"></ion-input>
                </div>
            </div>

            <ng-template #noPlateSelected>
                <div class="visible-identifier-instructions">
                    <ion-label class="instructions">
                        Place the following number on your dashboard for the expert to identify your vehicle.
                    </ion-label>
                    <ion-label class="identifier">
                        {{visibleIdentifier}}
                    </ion-label>
                </div>
            </ng-template>

            <div *ngIf="visibleForm !== 'plate_lookup'">
                <ion-input
                           placeholder="Year"
                           [formControlName]="vehicleFormControls.YEAR_FORM_CONTROL"
                           class="vehicle-input input-with-border readonly"
                           readonly="true"
                           (click)="showSupportedYears()">
                    <ion-spinner *ngIf="vehicleSelectionLoading" name="dots"></ion-spinner>
                </ion-input>

                <ion-input
                        placeholder="Make"
                        [formControlName]="vehicleFormControls.MAKE_FORM_CONTROL"
                        *ngIf="!vehicleFormGroup.hasError('required', vehicleFormControls.YEAR_FORM_CONTROL)"
                        class="vehicle-input input-with-border readonly"
                        readonly="true"
                        (click)="showSupportedMakes()">
                    <ion-spinner *ngIf="vehicleSelectionLoading" name="dots"></ion-spinner>
                </ion-input>

                <ion-input
                           placeholder="Model"
                           [formControlName]="vehicleFormControls.MODEL_FORM_CONTROL"
                           *ngIf="!vehicleFormGroup.hasError('required', vehicleFormControls.MAKE_FORM_CONTROL)"
                           class="vehicle-input input-with-border readonly"
                           readonly="true"
                           (click)="showSupportedModels()">
                    <ion-spinner *ngIf="vehicleSelectionLoading" name="dots"></ion-spinner>
                </ion-input>

                <ion-input
                           placeholder="Trim"
                           *ngIf="!vehicleFormGroup.hasError('required', vehicleFormControls.MODEL_FORM_CONTROL)
                                && state?.selection?.data?.vehicleSelectionTrims?.length"
                           class="vehicle-input input-with-border readonly"
                           readonly="true"
                           [formControlName]="vehicleFormControls.TRIM_FORM_CONTROL"
                           (click)="showSupportedTrims()">
                    <ion-spinner *ngIf="vehicleSelectionLoading" name="dots"></ion-spinner>
                </ion-input>
            </div>

            <ion-item *ngIf="vehicleSelectionError" lines="none">
                <ion-label color="danger" class="gb-small-text-size">
                    {{vehicleSelectionError}}
                </ion-label>
            </ion-item>

            <ion-button
                    type="submit"
                    expand="block"
                    color="primary"
                    class="ion-margin-top"
                    [disabled]="addingVehicleLoading || state?.selection?.loading || !vehicleFormGroup.valid"
            >
                <ion-spinner *ngIf="addingVehicleLoading || state?.selection?.loading; else notLoading" name="dots"></ion-spinner>
                <ng-template #notLoading>
                    <ion-label *ngIf="visibleForm === 'plate_lookup'; else manualEntry">
                        Search by License Plate
                    </ion-label>
                    <ng-template #manualEntry>
                        <ion-label>Add</ion-label>
                    </ng-template>
                </ng-template>
            </ion-button>
        </form>
        <div *ngIf="visibleForm === 'plate_lookup'">
            <div class="license-plate-search-result" *ngIf="licensePlateSearchResult as vehicle">
                <ion-label class="search-result-label">
                    {{vehicle.year}}
                    {{vehicle.make | titlecase}}
                    {{vehicle.model | titlecase}}
                </ion-label>
                <ion-button color="primary" expand="block" fill="outline"
                            [disabled]="!vehicle.license_plate_state || !vehicle.license_plate || (vehicle.license_plate && addingVehicleLoading)"
                            (click)="searchByLicensePlate(true)">
                    <ion-spinner *ngIf="vehicle.license_plate && addingVehicleLoading; else notLoading"
                                 name="dots"></ion-spinner>
                    <ng-template #notLoading>
                        <ion-label class="gb-small-text-size">Confirm</ion-label>
                    </ng-template>
                </ion-button>
            </div>
        </div>
    </div>
</ion-content>
