
<!--<ion-content class="ion-padding">-->
<div id="recaptcha-container"></div>
<div *ngIf="userState$ | async as userState">
    <ion-label class="gb-page-title" *ngIf="showHeader">
        {{userState.user?.phone_number ? 'Edit' : 'Add'}} Phone Number
    </ion-label>

    <div *ngIf="!showConfirmPhoneField; else confirmationNumberFormView">
        <!--            <div class="ion-margin-vertical">-->
        <!--                <ion-label class="gb-small-text-size" color="medium">-->
        <!--                    Phone number is used as a form of communication regarding your reservations and will not be shared with the experts.-->
        <!--                </ion-label>-->
        <!--            </div>-->
        <form [formGroup]="phoneNumberForm"
              (ngSubmit)="startVerification(phoneNumberForm.value)">
            <ion-input type="tel"
                       placeholder="Phone Number"
                       class="form-input"
                       formControlName="phone_number"
                       (ionInput)="handlePhoneInput($event)"
                       value={{phoneNumber}}
                       maxlength="14"></ion-input>
            <ion-button id="login-by-phone-button"
                        color="primary" type="submit" class="ion-margin-top ion-text-center" expand="block"
                        [disabled]="phoneNumberFormHasErrors() || userState.loading">
                <ion-spinner name="dots" duration="undefined" *ngIf="userState.loading; else loaded"></ion-spinner>
                <ng-template #loaded>
                    Continue
                </ng-template>
            </ion-button>
            <div class="ion-text-left">
                <ion-label class="gb-small-text-size" color="medium">
                    We'll text you a confirmation code. Message and data rates may apply.
                </ion-label>
            </div>
        </form>
    </div>
    <ng-template #confirmationNumberFormView>
        <form class="ion-margin-top"
              [formGroup]="confirmPhoneNumberForm"
              (ngSubmit)="confirmPhoneNumber(confirmPhoneNumberForm.value)">
            <ion-label class="gb-small-text-size">Enter the code sent to {{phoneNumber}}</ion-label>
            <ion-input type="tel" class="form-input" placeholder="Code" formControlName="code"></ion-input>
            <ion-button color="primary" type="submit" class="ion-margin-top ion-text-center" expand="block"
                        [disabled]="userState.loading || confirmPhoneFormHasErrors()">
                <ion-spinner name="dots" duration="undefined" *ngIf="userState.loading; else loaded"></ion-spinner>
                <ng-template #loaded>
                    Confirm
                </ng-template>
            </ion-button>
            <ion-button color="primary" (click)="startVerification(phoneNumberForm.value)"
                        fill="outline"
                        class="ion-margin-top ion-text-center" expand="block"
                        [disabled]="disableResendButton">
                Resend Code <span *ngIf="this.countdown$ | async as countdown"> ({{countdown}})</span>
            </ion-button>
            <ion-button color="primary" (click)="close()"
                        fill="outline"
                        *ngIf="showBackButton"
                        class="ion-margin-top ion-text-center" expand="block"
                        [disabled]="userState.loading">
                Go Back
            </ion-button>
        </form>
    </ng-template>
</div>
<!--</ion-content>-->
