import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PageComponent} from "./page/page.component";
import {IonicModule} from "@ionic/angular";
import {ProfilePageListModule} from "../profile-page-list/profile-page-list.module";
import {LoginPageModule} from "../login-page/login-page.module";
import {TopNavbarComponent} from "./top-navbar/top-navbar.component";
import {SearchInputComponent} from "../search-input/search-input.component";


@NgModule({
    declarations: [
        PageComponent,
        TopNavbarComponent,
        SearchInputComponent,
    ],
    imports: [
        CommonModule,
        IonicModule,
        ProfilePageListModule,
        LoginPageModule
    ],
    exports: [
        PageComponent,
        SearchInputComponent,
    ]
})
export class CommonComponentsModule { }
