import {User as UserModels} from "@gobubbleapp/models";
import {User as FirebaseUser} from '@angular/fire/auth';

export interface User extends UserModels {
    is_anonymous?: boolean;
}

export function fromFirebaseUser(firebaseUser?: FirebaseUser): User | undefined {
    if (!firebaseUser) {
        return undefined;
    }

    return {
        uid: firebaseUser.uid,
        email: firebaseUser.email,
        verified: firebaseUser.emailVerified,
        is_anonymous: firebaseUser.isAnonymous,
        full_name: firebaseUser.displayName,
        phone_number: firebaseUser.phoneNumber
    } as User
}
