import * as fromUser from '../actions/user.actions';
import {User} from '../models/user';
import {PhoneNumberVerificationResult} from '../services/user.service';
import {LoginSignUpFormType} from "../actions/user.actions";

// export enum EmailLoginState {
//     NOT_DEFINED,
//     UNAUTHENTICATED,
//     EMAIL_SENT,
//     LOGGING_IN
//     // ADD_PHONE_NUMBER,
//     // CONFIRM_PHONE_NUMBER,
// }

export interface UserState {
    phoneNumberVerificationResult?: PhoneNumberVerificationResult;
    user?: User;
    // loginRequestEmail: string | null;
    // emailLoginState: EmailLoginState;
    phoneNumberPendingVerification: string | null;
    loading: boolean;
    staticMapImageUrls: {[key: string]: string};
    signedInWithApple?: boolean;
    loginSignUpVisibleForm?: LoginSignUpFormType;
    userProviderId?: string;
}

export const initialState: UserState = {
    // loginRequestEmail: null,
    // emailLoginState: EmailLoginState.NOT_DEFINED,
    phoneNumberPendingVerification: null,
    loading: false,
    staticMapImageUrls: {},
    loginSignUpVisibleForm: LoginSignUpFormType.PHONE_LOGIN
};

export function reducer(
    state = initialState,
    action: fromUser.ActionsUnion
): UserState {
    switch (action.type) {
        case fromUser.ActionTypes.RESET_USER_VERIFICATION: {
            return {
                ...state,
                phoneNumberVerificationResult: undefined,
                phoneNumberPendingVerification: null
            }
        }

        case fromUser.ActionTypes.START_USER_VERIFICATION: {
            // We intentionally do not set loading to true because the recaptcha container must be loaded on the screen
            return {
                ...state,
                phoneNumberPendingVerification: action.phoneNumber,
                phoneNumberVerificationResult: null
            };
        }

        case fromUser.ActionTypes.PHONE_NUMBER_VERIFICATION_STATUS:
            // We intentionally do not set loading to true because the recaptcha container must be loaded on the screen
            return {
                ...state,
                loading: false,
                phoneNumberVerificationResult: action?.result === undefined
                    ? state.phoneNumberVerificationResult
                    : action.result,
            };

        case fromUser.ActionTypes.VERIFY_USER:
            // We intentionally do not set loading to true because the recaptcha container must be loaded on the screen
            return {
                ...state,
            };

        // case fromUser.ActionTypes.USER_AUTH_STATE_CHANGED: {
        //     const firebaseUser = action.firebaseUser;
        //     let user;
        //     if (firebaseUser) {
        //         user = {
        //             uid: firebaseUser.uid,
        //             phone_number: firebaseUser.phoneNumber
        //         };
        //     }
        //     return {
        //         ...state,
        //         user,
        //         loading: false
        //     };
        // }

        case fromUser.ActionTypes.AUTHENTICATED: {
            return {
                ...state,
                loading: false
            };
        }

        case fromUser.ActionTypes.UPDATE_USER_INFO:
            return {
                ...state,
                user: action.user
            };

        // case fromUser.ActionTypes.EMAIL_LOGIN_REQUESTED: {
        //     return {
        //         ...state,
        //         loginRequestEmail: action.email,
        //         loading: true
        //     };
        // }
        //
        // case fromUser.ActionTypes.LOGIN_EMAIL_SENT: {
        //     return {
        //         ...state,
        //         emailLoginState: EmailLoginState.EMAIL_SENT,
        //         loading: false
        //     };
        // }
        //
        // case fromUser.ActionTypes.CONTINUE_EMAIL_LOGIN: {
        //     return {
        //         ...state,
        //         emailLoginState: EmailLoginState.LOGGING_IN
        //     };
        // }
        //
        // case fromUser.ActionTypes.UPDATE_LOGIN_STATE: {
        //     if (action.loginState === EmailLoginState.UNAUTHENTICATED) {
        //         return {
        //             ...state,
        //             emailLoginState: EmailLoginState.UNAUTHENTICATED,
        //             loginRequestEmail: null,
        //             loading: false,
        //             signedInWithApple: undefined,
        //             phoneNumberPendingVerification: null
        //         };
        //     } else {
        //         return {
        //             ...state,
        //             emailLoginState: action.loginState
        //         };
        //     }
        // }

        case fromUser.ActionTypes.ERROR_LOGGING_IN:
            return {
                ...state,
                loading: false
                // emailLoginState: EmailLoginState.UNAUTHENTICATED
            };

        case fromUser.UserLogoutSuccess:
            return initialState;

        case fromUser.StartUserLogout:
            return {
                ...state,
                loading: true
            };

        case fromUser.UserLogoutFailure:
            return {
                ...state,
                loading: false
            }

        case fromUser.ActionTypes.DISPLAY_LOGIN_SIGN_UP_FORM:
            return {
                ...state,
                loginSignUpVisibleForm: action.formType ? action.formType : initialState.loginSignUpVisibleForm,
                loading: false
            }

        case fromUser.ActionTypes.SIGN_UP_WITH_EMAIL_PASSWORD:
        case fromUser.ActionTypes.SIGN_IN_WITH_EMAIL:
        case fromUser.ActionTypes.CHECK_EMAIL_PROVIDERS:
        case fromUser.ActionTypes.REQUEST_PASSWORD_RESET_EMAIL:
        case fromUser.StartUserLogout.type:
        case fromUser.SignInWithProvider:
            return {
                ...state,
                loading: true
            }

        case fromUser.ActionTypes.SIGN_UP_FAILURE:
        case fromUser.ActionTypes.SIGN_IN_FAILURE:
        case fromUser.ActionTypes.SIGN_UP_SUCCESS:
        case fromUser.ActionTypes.REQUEST_PASSWORD_RESET_EMAIL_SUCCESS:
        case fromUser.ActionTypes.REQUEST_PASSWORD_RESET_EMAIL_FAILURE:
        case fromUser.UserLogoutSuccess.type:
        case fromUser.UserLogoutFailure.type:
            return {
                ...state,
                loading: false
            }

        case fromUser.ActionTypes.SIGN_IN_SUCCESS:
            return {
                ...state,
                loading: false,
                userProviderId: action.providerId
            }

        default: {
            return state;
        }
    }
}

export const userLoadingState = (state: UserState) => state.loading;
export const userObjectState = (state: UserState) => state.user;
export const staticMapImageUrlsState = (state: UserState) => state.staticMapImageUrls;

export const selectPhoneNumberVerificationResult = (state: UserState) => state.phoneNumberVerificationResult;
