import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'positiveOrZeroInt'
})
export class PositiveOrZeroPipeInt implements PipeTransform {
    transform(num: string): number {
        const int = parseInt(num, 10);
        if (isNaN(int)) {
            return 0;
        }
        return Math.max(0, int);
    }
}

@Pipe({
    name: 'stringReplace'
})
export class StringReplacePipe implements PipeTransform {
    transform(value: string, regexValue: string, replaceValue: string): string {
        return value.replace(new RegExp(regexValue, 'g'), replaceValue);
    }
}
