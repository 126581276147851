<ion-header>
    <ion-toolbar class="gb-top-toolbar">
        <ion-buttons slot="start">
            <ion-button (click)="close()">
                <ion-icon slot="icon-only" color="dark" name="close-outline"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>

    <div class="gb-page-title-container">
        <ion-label class="gb-page-title">
            {{providerService?.displayProperties?.title ?? 'Tell us more'}}
        </ion-label>
    </div>

    <div class="container">
        <div class="vehicle-item-selections">
<!--             *ngIf="providerService?.options?.length > 1">-->
            <div class="prompt-container">
                <div class="prompt-row">
                    <ion-label class="prompt">
                        {{providerService?.displayProperties?.prompt || 'Select an option for ' + (providerService?.displayProperties?.title | titlecase)}}
                        <!--                            Overall, how dirty is the {{selectedVehicle.make | titlecase}} {{selectedVehicle.model | titlecase}}?-->
                    </ion-label>
                    <ion-icon
                            *ngIf="providerService?.displayProperties?.additional_info as info"
                            name="information-circle-outline"
                            (click)="additionalInfoPopoverContent = info">
                    </ion-icon>
                </div>
            </div>
            <div class="option-buttons">
                <!-- [fill]="selectedDirtyLevel === dirtyLevel.key ? 'solid' : 'outline'"-->
                <ion-button expand="block"
                            *ngFor="let option of providerService?.options"
                            [fill]="serviceSelectionForm?.controls?.[providerService.id]?.value === option.id ? 'solid' : 'outline'"
                            (click)="optionSelected({key: providerService.id, option: option.id}, false)">
                    <ion-label>
<!--                            *ngIf="{addition: providerService | washTypePlanDirtyLevelAddition: option.id} as addition">-->
                        {{option.name}}
                        <span *ngIf="option.price !== undefined" class="gb-xsmall-text-size">
                            (+{{option.price/100 | currency}})
                        </span>
<!--                        <span *ngIf="addition.addition !== undefined" class="gb-xsmall-text-size">-->
<!--                                (+{{addition.addition/100 | currency}})-->
<!--                            </span>-->
                    </ion-label>
                </ion-button>
            </div>
        </div>
        <div>
            <div *ngFor="let addOn of requiredAddOnOptionSelection; index as i">
                <div *ngIf="i < currentFormStep">
                    <div class="vehicle-item-selections" *ngIf="addOn?.service as addOnService">
                        <!--                         *ngIf="i === 0 ? selectedVehicleOptionId : selectedAddOnItems.get(addOnsWithPrompts[i - 1].identifier)">-->
                        <div class="prompt-container">
                            <div class="prompt-row">
                                <ion-label class="prompt">
                                    <!--                                    {{prompt}}-->
                                    {{addOnService?.displayProperties?.prompt || 'Select an option for ' + (addOnService?.displayProperties?.title | titlecase)}}
                                </ion-label>
                                <ion-icon *ngIf="addOnService?.displayProperties.additional_info as info" name="information-circle-outline"
                                          (click)="additionalInfoPopoverContent = info"></ion-icon>
                            </div>
                            <ion-label color="medium" class="gb-small-text-size included-dirty-level"
                                       *ngIf="addOn?.included || addOn?.included_options?.length">
                                Included options have no extra charge.
                            </ion-label>
                        </div>
                        <div class="option-buttons">
                            <!-- [fill]="selectedDirtyLevel === dirtyLevel.key ? 'solid' : 'outline'"-->
                            <ion-button
                                    *ngIf="addOnService?.displayProperties?.allow_none_option"
                                    [fill]="serviceSelectionForm?.controls[addOnService.id]?.value === NONE_OPTION_ID ? 'solid' : 'outline'"
                                    expand="block"
                                    (click)="optionSelected({key: addOnService.id, option: NONE_OPTION_ID}, false)"
                            >
                                <ion-label>
                                    None
                                </ion-label>
                            </ion-button>
                            <ion-button
                                    [fill]="serviceSelectionForm?.controls[addOnService.id]?.value === option.id ? 'solid' : 'outline'"
                                    expand="block"
                                    *ngFor="let option of addOnService?.options; i as index"
                                    (click)="optionSelected({key: addOnService.id, option: option.id}, false)"
                            >
                                <ion-label>
                                    {{option.name ?? 'Option ' + (i + 1)}}
                                    <span class="gb-xsmall-text-size"
                                          *ngIf="addOn.included || (addOn.included_options | gbFindId: option.id); else showPrice">
                                        {{INCLUDED_TEXT}}
                                    </span>
                                    <ng-template #showPrice>
                                        <span *ngIf="option.price !== undefined" class="gb-xsmall-text-size">
                                            (+{{option.price/100 | currency}})
                                        </span>
                                    </ng-template>
                                </ion-label>
                            </ion-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="add-ons" *ngIf="currentFormStep >= totalFormSteps">
            <app-items-list
                    *ngIf="notRequiredAddOnItems?.length > 0"
                    [items]="notRequiredAddOnItems"
                    (itemSelected)="optionSelected($event)"
                    itemBorder="true"
                    title="Add-Ons"
                    subtitle="Add-ons are optional. Additional charges apply. Included options have no extra charge."
                    noItemsText="Error loading add-ons"
                    listBorderTop="true"
            ></app-items-list>
        </div>
        <app-floating-button
                [disabled]="serviceSelectionForm?.errors | values | some: isTrue"
                [buttonText]="(updatingItem ? 'Update' : 'Add')
                    + ' (' + (selectionTotal/100 | currency) + ')'"
                [loading]="loading"
                (buttonClicked)="addToCart()">
        </app-floating-button>
    </div>
    <ion-popover show-backdrop="true"
                 size="cover"
                 css-class="gb-center-popover"
                 [isOpen]="!!additionalInfoPopoverContent"
                 (ionPopoverDidDismiss)="this.additionalInfoPopoverContent = undefined">
        <ng-template>
            <ion-label *ngIf="additionalInfoPopoverContent"
                       [innerHTML]="additionalInfoPopoverContent"></ion-label>
        </ng-template>
    </ion-popover>
</ion-content>
