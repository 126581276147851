import {ActionReducerMap, createSelector,} from '@ngrx/store';
import * as fromUser from './user.reducer';
import * as fromApp from './app.reducer';
import * as fromNotifications from './notifications.reducer';
import * as fromCart from './cart.reducer';
import * as fromDiscount from './discount.reducer';
import * as fromWashServices from './wash-services.reducer';
import * as fromMailing from './marketing.reducer';

export interface State {
    userState: fromUser.UserState;
    app: fromApp.AppState;
    notifications: fromNotifications.NotificationsState;
    cart: fromCart.CartState;
    discount: fromDiscount.DiscountState;
    washServices: fromWashServices.WashServicesState;
    marketing: fromMailing.MarketingState;
}

export const reducers: ActionReducerMap<State> = {
    userState: fromUser.reducer,
    app: fromApp.reducer,
    notifications: fromNotifications.reducer,
    cart: fromCart.reducer,
    discount: fromDiscount.reducer,
    washServices: fromWashServices.reducer,
    marketing: fromMailing.reducer
};

export const getUserState = (state: State) => state.userState;
export const getAppState = (state: State) => state.app;
export const errors = createSelector(
    getAppState,
    fromApp.error
);

export const cartState = (state: State) => state.cart;
export const discountState = (state: State) => state.discount;
export const selectMarketing = (state: State) => state.marketing;
