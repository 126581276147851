import {Component} from '@angular/core';

@Component({
    selector: 'app-supported-locations',
    templateUrl: './supported-locations.component.html',
    styleUrls: ['./supported-locations.component.scss'],
})
export class SupportedLocationsComponent {

    constructor() { }

}
