import * as Types from './generated/graphql-schema';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type QueryVehicleSelectionQueryVariables = Types.Exact<{
  year: Types.Scalars['String'];
  make: Types.Scalars['String'];
  model: Types.Scalars['String'];
  includeMakes?: Types.InputMaybe<Types.Scalars['Boolean']>;
  includeModels?: Types.InputMaybe<Types.Scalars['Boolean']>;
  includeTrims?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type QueryVehicleSelectionQuery = { __typename?: 'Query', vehicleSelectionYears: Array<{ __typename?: 'VehicleYearSelectionEntry', id: string, name: string }>, vehicleSelectionMakes?: Array<{ __typename?: 'VehicleMakeSelectionEntry', id: string, name: string }>, vehicleSelectionModels?: Array<{ __typename?: 'VehicleModelSelectionEntry', id: string, name: string }>, vehicleSelectionTrims?: Array<{ __typename?: 'VehicleTrimSelectionEntry', id: string, name: string }> };

export const QueryVehicleSelectionDocument = gql`
    query QueryVehicleSelection($year: String!, $make: String!, $model: String!, $includeMakes: Boolean = false, $includeModels: Boolean = false, $includeTrims: Boolean = false) {
  vehicleSelectionYears {
    id
    name
  }
  vehicleSelectionMakes(year: $year) @include(if: $includeMakes) {
    id
    name
  }
  vehicleSelectionModels(year: $year, make: $make) @include(if: $includeModels) {
    id
    name
  }
  vehicleSelectionTrims(year: $year, make: $make, model: $model) @include(if: $includeTrims) {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryVehicleSelectionGQL extends Apollo.Query<QueryVehicleSelectionQuery, QueryVehicleSelectionQueryVariables> {
    document = QueryVehicleSelectionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }