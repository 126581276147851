import * as fromApp from '../actions/app.actions';

export interface AppState {
    error: any;
    openUrl: null | string;
    isActive: boolean;
}

export const initialState: AppState = {
    error: undefined,
    openUrl: null,
    isActive: true
};

export function reducer(
    state = initialState,
    action: fromApp.ActionsUnion
): AppState {
    switch (action.type) {
        case fromApp.ActionTypes.APP_ERROR: {
            return {
                ...state,
                error: action.error
            };
        }

        case fromApp.ActionTypes.OPEN_URL: {
            return {
                ...state,
                openUrl: action.openUrl
            };
        }

        case fromApp.ActionTypes.REMOVE_OPEN_URL: {
            return {
                ...state,
                openUrl: null
            };
        }

        default: {
            return state;
        }
    }
}

export const error = (state: AppState) => state.error;
export const getOpenUrl = (state: AppState) => state.openUrl;
