<div class="container" [class.collapsed]="collapsed" [class.half-horizontal-margin]="halfHorizontalMargin">
    <div class="inputs">
        <ion-item class="item gb-transparent"
                  button="true"
                  detail="false"
                  (click)="vehicleInputSelected.emit()"
                  [lines]="collapsed ? 'none' : undefined">
            <ion-icon slot="start" name="car-sport-outline" color="primary"></ion-icon>
            <ion-spinner name="dots" duration="undefined" *ngIf="loadingVehicle === true; else vehicleLoaded"></ion-spinner>
            <ng-template #vehicleLoaded>
                <ion-label *ngIf="vehicle as vehicle; else showDefaultVehicleLabel" class="ellipsis">
                    {{vehicle.year}} {{vehicle.make}} {{vehicle.model}} - {{vehicle.license_plate_state}} - {{vehicle.license_plate | uppercase}}
                </ion-label>
                <ng-template #showDefaultVehicleLabel>
                    <ion-label color="primary">
                        Step 1: Add a vehicle
                    </ion-label>
                </ng-template>
            </ng-template>
        </ion-item>
        <ion-item class="item gb-transparent"
                  button="true"
                  detail="false"
                  (click)="searchInputSelected.emit()"
                  lines="none">
            <ion-icon slot="start" name="location" color="primary"></ion-icon>
            <ion-spinner name="dots" duration="undefined" *ngIf="loadingLocation === true; else locationLoaded"></ion-spinner>
            <ng-template #locationLoaded>
                <ion-label *ngIf="orderLocation?.address as address; else showDefault" class="ellipsis">
                    {{address}}
                </ion-label>
                <ng-template #showDefault>
                    <ion-label color="primary">
                        Step 2: Select Location
                    </ion-label>
                </ng-template>
            </ng-template>
        </ion-item>
    </div>
</div>
