import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl} from "@angular/forms";

export interface ItemOption {
    key: string;
    value: string;
    disabled?: boolean;
    valueSuffix?: string;
}

export interface Item {
    key: string;
    text: string;
    icon?: string;
    subtitle?: string;
    additional_information?: string;
    display_order?: number;
    more_options?: ItemOption[];
    disabled?: boolean;
    buttonTextSuffix?: string;
    /**
     * Whether the user must select an option from the more_options list.
     * This will only apply if the more_options list is greater than one.
     */
    option_selection_required?: boolean;
    formControl?: FormControl;
}

@Component({
    selector: 'app-items-list',
    templateUrl: './items-list.component.html',
    styleUrls: ['./items-list.component.scss']
})
export class ItemsListComponent {
    @Input() title: string;
    @Input() subtitle?: string;
    @Input() items: Item[];
    @Input() noItemsText: string;

    @Input() showAddNewItem = false;
    @Input() itemBorder = false;
    @Input() listBorderTop = false;

    @Output() selectNewItem = new EventEmitter();
    @Output() itemSelected = new EventEmitter<{key: string, option?: string}>();

    additionalInfoPopoverContent: string;
    SELECT_TEXT = 'Select';
    SELECTED_TEXT = 'Selected';

    constructor(
    ) { }

    onItemSelected(key: string, option?: string) {
        // const formControl = this.items.find(item => item.key === key)?.formControl
         // supporting deselect
        // formControl?.setValue(formControl?.value === option ? undefined : option);
        this.itemSelected.emit({key, option});
    }

    newItemSelected() {
        this.selectNewItem.emit();
    }

    disabledOption(option: {key: string; value: string, disabled?: boolean}) {
        return option.disabled;
    }
}
