import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

export interface GridItem {
    key: string;
    text: string;
}

@Component({
    selector: 'app-grid-buttons',
    templateUrl: './grid-buttons.component.html',
    styleUrls: ['./grid-buttons.component.scss'],
})
export class GridButtonsComponent implements OnInit {
    @Input() items: GridItem[];
    @Input() isTransparentButton = false;
    @Input() buttonColor = 'primary';
    @Input() public title;
    @Input() public selectedItem: string;
    @Input() public noItemsText: string;
    @Input() useGlobalTitleStyle = false;

    @Output() public itemSelected: EventEmitter<string> = new EventEmitter();

    public grid: GridItem[][];

    constructor() { }

    ngOnInit() {
        this.grid = [];
        for (let i = 0; i < this.items.length; i += 2) {
            if (this.items.length - 1 === i) {
                this.grid.push([this.items[i]]);
            } else {
                this.grid.push([this.items[i], this.items[i + 1]]);
            }
        }
    }
}
