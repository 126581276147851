import {Component, EventEmitter, Input, Output} from '@angular/core';
import {StartUserLogout} from "../actions/user.actions";
import {ContactSupport, NavigateForwardRelativeToRoot, OpenLinkInBrowser} from "../actions/app.actions";
import {environment} from "../../environments/environment";
import {State} from "../reducers";
import {Observable} from "rxjs";
import {Store} from "@ngrx/store";
import {User} from '../models/user';
import {nanoid} from "nanoid";

@Component({
    selector: 'app-profile-page-list',
    templateUrl: './profile-page-list.component.html',
    styleUrls: ['./profile-page-list.component.scss']
})
export class ProfilePageListComponent {

    @Input() user: User;
    @Input() loading$: Observable<boolean>;
    @Input() showDetailIcon = true;

    @Output() loginButtonClicked = new EventEmitter();

    public items = [
        {
            icon: 'person-outline',
            action: () => this.login(),
            label: 'Login / Sign Up',
            onlyShowWhenLoggedIn: false
        },
        {
            icon: 'person-outline',
            action: () => this.openPersonalAccount(),
            label: 'Personal Information',
            onlyShowWhenLoggedIn: true
        },
        {
            icon: 'help-buoy-outline',
            action: () => this.openHelp(),
            label: "Get Help"
        },
        {
            icon: 'document-text-outline',
            action: () => this.onFeedback(),
            label: "Feedback"
        }
    ]

    constructor(
        private store: Store<State>
    ) { }

    logout() {
        this.store.dispatch(StartUserLogout({correlationId: nanoid()}));
    }

    onFeedback() {
        this.store.dispatch(new ContactSupport({subject: 'Feedback'}));
    }

    openHelp() {
        this.store.dispatch(new OpenLinkInBrowser(environment.helpCenterUrl + '/hc/en-us/sections/360009792013'));
    }

    openPersonalAccount() {
        this.store.dispatch(NavigateForwardRelativeToRoot({
            correlationId: nanoid(),
            path: ['profile', 'personal-info']
        }));
    }

    login() {
        this.loginButtonClicked.emit();
    }
}
