import moment from 'moment';

export const weekday = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
];

export function getFirstDateFromWeekday(day: string): string | null {
    const index = weekday.indexOf(day);

    if (index < 0) {
        return null;
    }

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    while (currentDate.getDay() !== index) {
        currentDate.setHours(currentDate.getHours() + 24);
    }

    return getDate(currentDate);
}

export function getDate(dateTime: Date | number): string {
    if (dateTime instanceof Date) {
        return addZero(dateTime.getFullYear()) + '-' + addZero(dateTime.getMonth() + 1) + '-' + addZero(dateTime.getDate());
    }

    if (typeof dateTime === 'number') {
        return moment.unix(dateTime).format('YYYY-MM-DD');
    }
}

function addZero(i: number): string {
    return i < 10 ? '0' + i : i + '';
}

export function dateInPast(date: string) {
    const d = new Date(date);
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    d.setHours(0, 0, 0, 0);
    return d < now;
}

export function updateMomentCalendar() {
    moment.updateLocale('en', {
        calendar : {
            lastDay : '[Yesterday]',
            sameDay : '[Today]',
            nextDay : '[Tomorrow]',
            lastWeek : 'dddd',
            nextWeek : 'dddd',
            sameElse : '[Next] dddd'
        }
    });
}
