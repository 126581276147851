import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ReservationPageComponent} from "./reservation-page.component";

export const RESERVATION_ID_PARAM = 'reservationId';
export const RESERVATION_CHANNEL_ID_PARAM = 'channelId';
export const RESERVATION_CHANNEL_ROUTE = 'channel';

const routes: Routes = [
    {
        path: RESERVATION_CHANNEL_ROUTE + '/:' + RESERVATION_CHANNEL_ID_PARAM,
        component: ReservationPageComponent
    },
    {
        path: ':' + RESERVATION_ID_PARAM,
        component: ReservationPageComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ReservationPageRoutingModule {}
