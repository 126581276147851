import * as Types from '../generated/graphql-schema';

import { gql } from 'apollo-angular';
export type PromotionCodeRestrictionsPropsFragment = { __typename?: 'PromotionCodeRestrictions', expires_at?: number | null, first_time_transaction?: boolean | null, min_vehicle_count?: number | null, minimum_amount?: number | null };

export type CouponRestrictionsPropsFragment = { __typename?: 'CouponRestrictions', expires_at?: number | null, first_time_transaction?: boolean | null, min_vehicle_count?: number | null, minimum_amount?: number | null };

export type CouponDisplayPropertiesPropsFragment = { __typename?: 'CouponDisplayProperties', title?: string | null, description?: string | null, display_location?: Types.PromotionCodeDisplayLocation | null, display_order?: number | null, image_url?: string | null };

export type ProviderPromotionCodeFragment = { __typename?: 'PromotionCode', id: string, code?: string | null, restrictions?: { __typename?: 'PromotionCodeRestrictions', expires_at?: number | null, first_time_transaction?: boolean | null, min_vehicle_count?: number | null, minimum_amount?: number | null } | null, coupon?: { __typename?: 'Coupon', id: string, name?: string | null, amount_off?: number | null, percent_off?: number | null, auto_apply?: boolean | null, restrictions?: { __typename?: 'CouponRestrictions', expires_at?: number | null, first_time_transaction?: boolean | null, min_vehicle_count?: number | null, minimum_amount?: number | null } | null, display_properties?: { __typename?: 'CouponDisplayProperties', title?: string | null, description?: string | null, display_location?: Types.PromotionCodeDisplayLocation | null, display_order?: number | null, image_url?: string | null } | null } | null };

export const PromotionCodeRestrictionsPropsFragmentDoc = gql`
    fragment PromotionCodeRestrictionsProps on PromotionCodeRestrictions {
  expires_at
  first_time_transaction
  min_vehicle_count
  minimum_amount
}
    `;
export const CouponRestrictionsPropsFragmentDoc = gql`
    fragment CouponRestrictionsProps on CouponRestrictions {
  expires_at
  first_time_transaction
  min_vehicle_count
  minimum_amount
}
    `;
export const CouponDisplayPropertiesPropsFragmentDoc = gql`
    fragment CouponDisplayPropertiesProps on CouponDisplayProperties {
  title
  description
  display_location
  display_order
  image_url
}
    `;
export const ProviderPromotionCodeFragmentDoc = gql`
    fragment ProviderPromotionCode on PromotionCode {
  id
  code
  restrictions {
    ...PromotionCodeRestrictionsProps
  }
  coupon {
    id
    name
    amount_off
    percent_off
    auto_apply
    restrictions {
      ...CouponRestrictionsProps
    }
    display_properties {
      ...CouponDisplayPropertiesProps
    }
  }
}
    ${PromotionCodeRestrictionsPropsFragmentDoc}
${CouponRestrictionsPropsFragmentDoc}
${CouponDisplayPropertiesPropsFragmentDoc}`;