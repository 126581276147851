import {Component, EventEmitter, Input, Output} from '@angular/core';
import {animate, style, transition, trigger} from "@angular/animations";

@Component({
    selector: 'app-floating-button',
    templateUrl: './floating-button.component.html',
    styleUrls: ['./floating-button.component.scss'],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('100ms', style({ opacity: 1 })),
            ]),
            transition(':leave', [
                animate('100ms', style({ opacity: 0 }))
            ])
        ]),
    ]
})
export class FloatingButtonComponent {
    @Input() buttonText = '';
    @Input() loading = false;
    @Input() buttonIcon?: string;
    @Input() buttonTextSecondLine?: string;
    @Input() disabled?: boolean;

    @Output() buttonClicked = new EventEmitter();

    constructor() { }

    onClick() {
        this.buttonClicked.emit();
    }
}
