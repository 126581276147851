<div>
    <!--    <h2 class="gb-section-title">-->
    <!--        Payment-->
    <!--    </h2>-->
    <ion-label class="gb-small-section-title with-half-padding">
        Your Order
    </ion-label>
    <div *ngIf="reservation?.items as items">
        <div *ngFor="let item of items">
            <gb-reservations-item-detail
                    [item]="item"
                    showPrices="true"
                    [expanded]="expandIfSingleItem && items.length < 2"
                    [reservationItemActionsTemplate]="showEditButtons ? reservationItemActionsTemplate : undefined">
                <ng-template #reservationItemActionsTemplate>
                    <div class="gb-flex-row ion-justify-content-between item-action-buttons">
                        <ion-button fill="clear" size="small"
                                    [disabled]="orderItemLoading[item?.id]"
                                    (click)="updateDraftReservationItem(item)">
                            <ion-spinner name="dots" duration="undefined"
                                         *ngIf="orderItemLoading[item?.id]; else editButtonLoaded"></ion-spinner>
                            <ng-template #editButtonLoaded>
                                Edit
                            </ng-template>
                        </ion-button>
                        <ion-button fill="clear" color="danger" size="small"
                                    [disabled]="orderItemLoading[item?.id]"
                                    (click)="deleteReservationItem(item)">
                            <ion-spinner name="dots" duration="undefined"
                                         *ngIf="orderItemLoading[item?.id]; else deleteButtonLoaded"></ion-spinner>
                            <ng-template #deleteButtonLoaded>
                                Delete
                            </ng-template>
                        </ion-button>
                    </div>
                </ng-template>
            </gb-reservations-item-detail>
        </div>
    </div>
    <div *ngIf="showInvoice">
        <ion-grid *ngIf="reservation?.invoice as invoice; else noInvoice">
            <!--            <ion-row class="receipt-item"-->
            <!--                     *ngFor="let invoiceItem of invoice.lines | orEmpty">-->
            <!--                <ion-col size="8">-->
            <!--                    <ion-label class="receipt-item-quantity" *ngIf="invoiceItem.quantity > 1">-->
            <!--                        {{invoiceItem.quantity}} x-->
            <!--                    </ion-label>-->
            <!--                    <ion-label class="first-line" *ngIf="invoiceItem.description">-->
            <!--                        {{invoiceItem.description}}-->
            <!--                    </ion-label>-->
            <!--                </ion-col>-->
            <!--                <ion-col size="4" class="ion-text-end ion-align-self-center">-->
            <!--                    <ion-label class="receipt-item-amount">-->
            <!--                        {{ invoiceItem.amount/100 | currency: (invoice.currency | uppercase): 'symbol' }}-->
            <!--                    </ion-label>-->
            <!--                </ion-col>-->
            <!--            </ion-row>-->

            <ion-row class="receipt-item" *ngIf="invoice?.subtotal as subtotalAmount">
                <ion-col size="8">
                    <ion-label class="item-description">
                        Subtotal
                    </ion-label>
                </ion-col>
                <ion-col size="4" class="ion-text-end">
                    <ion-label class="receipt-item-amount">
                        {{subtotalAmount/100 | currency: (invoice.currency | uppercase): 'symbol'}}
                    </ion-label>
                </ion-col>
            </ion-row>

            <ion-row *ngFor="let discountAmount of invoice.total_discount_amounts | orEmpty" class="receipt-item">
                <ion-col size="8">
                    <ion-label class="item-description">
                        {{discountAmount.description ?? 'Discount'}}
                    </ion-label>
                </ion-col>
                <ion-col size="4" class="ion-text-end">
                    <ion-label class="receipt-item-amount">
                        -{{(discountAmount.amount ?? 0)/100 | currency: (invoice.currency | uppercase): 'symbol'}}
                    </ion-label>
                </ion-col>
            </ion-row>

            <ion-row *ngFor="let line of invoice.misc_lines | orEmpty" class="receipt-item">
                <ion-col size="8">
                    <ion-label class="receipt-item-quantity" *ngIf="line.quantity > 1">
                        {{line.quantity}} x
                    </ion-label>
                    <ion-label class="item-description">
                        {{line.description ?? 'Misc item'}}
                    </ion-label>
                </ion-col>
                <ion-col size="4" class="ion-text-end">
                    <ion-label class="receipt-item-amount">
                        {{(line.amount ?? 0)/100 | currency: (invoice.currency | uppercase): 'symbol'}}
                    </ion-label>
                </ion-col>
            </ion-row>

            <ion-row *ngIf="invoice.tax" class="receipt-item">
                <ion-col size="8">
                    <ion-label class="item-description">
                        Sales Tax
                    </ion-label>
                </ion-col>
                <ion-col size="4" class="ion-text-end">
                    <ion-label class="receipt-item-amount">
                        {{(invoice.tax ?? 0)/100 | currency: (invoice.currency | uppercase): 'symbol'}}
                    </ion-label>
                </ion-col>
            </ion-row>

            <ion-row class="receipt-item with-top-border">
                <ion-col size="8">
                    <ion-label class="item-description">
                        <b>Total</b>
                    </ion-label>
                </ion-col>
                <ion-col size="4" class="ion-text-end">
                    <ion-label class="receipt-item-amount">
                        {{(invoice.total ?? 0)/100 | currency: (invoice.currency | uppercase): 'symbol'}}
                    </ion-label>
                </ion-col>
            </ion-row>

            <ng-container *ngIf="showScheduledPayments">
                <ion-row class="receipt-item" *ngFor="let scheduledPayment of invoice.scheduled_payments">
                    <ion-col size="8">
                        <ion-label class="item-description">
                            Due
                            <span *ngIf="scheduledPayment.amount_due_at === 0">
                            today
                        </span>
                            <span *ngIf="scheduledPayment.amount_due_at > 0">
                            on {{scheduledPayment.amount_due_at | epochToDateText}}
                        </span>
                            <span *ngIf="scheduledPayment.amount_due_when_status">
                            when {{scheduledPayment.amount_due_when_status | reservationStatusToFriendlyText }}
                        </span>
                        </ion-label>
                    </ion-col>
                    <ion-col size="4" class="ion-text-end" *ngIf="scheduledPayment.amount_due">
                        <ion-label class="receipt-item-amount">
                            {{scheduledPayment.amount_due/100 | currency: (invoice.currency | uppercase): 'symbol'}}
                        </ion-label>
                    </ion-col>
                </ion-row>
            </ng-container>

        </ion-grid>
        <ng-template #noInvoice>
            <ion-grid>
                <ion-row>
                    <ion-col>
                        <ion-label class="first-line">
                            Error getting payment info or info is not yet available.
                        </ion-label>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ng-template>
    </div>
</div>
    <!--        <ng-template #noItems>-->
    <!--            <ion-grid>-->
    <!--                <ion-row>-->
    <!--                    <ion-col>-->
    <!--                        <div class="multi-line-labels">-->
    <!--                            <ion-label class="first-line">-->
    <!--                                Your card was not charged-->
    <!--                            </ion-label>-->
    <!--                        </div>-->
    <!--                    </ion-col>-->
    <!--                </ion-row>-->
    <!--            </ion-grid>-->
    <!--        </ng-template>-->
