import {throwError} from 'rxjs';

export function handleErrorMessage(e: any) {
    console.error('Handling error message', e);

    if (!navigator.onLine) {
        return throwError('No internet connection detected');
    }

    if (typeof e === 'string') {
        return throwError(e);
    }

    if (typeof e === 'object' && e.error && typeof e.error === 'object' && e.error.message) {
        return throwError(e.error.message);
    }

    if (typeof e === 'object' && e.message) {
        return throwError(e.message);
    }

    return throwError('Internal error. Please try again');
}
