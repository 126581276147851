<div @fadeInOut class="bottom-button-container">
    <ion-button class="bottom-button"
                expand="block"
                color="primary"
                (click)="onClick()"
                [disabled]="disabled || loading"
    >
        <ion-spinner name="dots" *ngIf="loading; else loaded"></ion-spinner>
        <ng-template #loaded>
            <ion-icon *ngIf="buttonIcon" slot="start" [name]="buttonIcon"></ion-icon>
            <ion-label>
                {{buttonText}}
            </ion-label>
        </ng-template>
    </ion-button>
</div>
