import * as Types from '../generated/graphql-schema';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetClientPaymentMethodsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetClientPaymentMethodsQuery = { __typename?: 'Query', selectedPaymentMethodId?: string | null, clientPaymentMethods?: Array<{ __typename?: 'PaymentMethod', id: string, card?: { __typename?: 'CardPaymentMethod', brand?: string | null, last4?: string | null } | null }> | null };

export const GetClientPaymentMethodsDocument = gql`
    query GetClientPaymentMethods {
  selectedPaymentMethodId @client
  clientPaymentMethods {
    id
    card {
      brand
      last4
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetClientPaymentMethodsGQL extends Apollo.Query<GetClientPaymentMethodsQuery, GetClientPaymentMethodsQueryVariables> {
    document = GetClientPaymentMethodsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }