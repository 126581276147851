import {Injectable} from '@angular/core';
import {AngularFireRemoteConfig} from '@angular/fire/compat/remote-config';
import {Observable} from 'rxjs';
import {fromPromise} from 'rxjs/internal-compatibility';
import {environment} from "../../environments/environment";

export const NEARBY_AVAILABILITIES_SEARCH_RADIUS = 'nearby_availabilities_search_radius_km';
export const USE_V2_BACKEND = 'use_v2_backend';
export const SHOW_PROMO_CODE_FIELD = 'show_promo_code_field';
export const CHECK_SUPPORTED_LOCATION = 'check_supported_location';
export const ALLOW_LOGIN_AS_GUEST = 'allow_login_as_guest';
export const STRIPE_PUBLISHABLE_KEY = 'stripe_publishable_key';

export const REMOTE_CONFIG_DEFAULTS = {
    [NEARBY_AVAILABILITIES_SEARCH_RADIUS]: 24,
    [USE_V2_BACKEND]: true,
    [SHOW_PROMO_CODE_FIELD]: true,
    [CHECK_SUPPORTED_LOCATION]: true,
    [ALLOW_LOGIN_AS_GUEST]: true,
    [STRIPE_PUBLISHABLE_KEY]: environment.stripe_publishable_key
};

@Injectable({
    providedIn: 'root'
})
export class RemoteConfigsService {

    public useV2Backend: boolean;
    public checkSupportedLocation: boolean;

    constructor(
        private remoteConfig: AngularFireRemoteConfig
    ) {
        this.remoteConfig.booleans[USE_V2_BACKEND].subscribe(useV2 => this.useV2Backend = useV2);
    }

    getBoolean(key: string): Observable<boolean> {
        return this.remoteConfig.booleans[key];
    }

    getNumber(key: string): Observable<number> {
        return fromPromise(this.remoteConfig.getNumber(key));
    }

    getString(key: string): Observable<string> {
        return this.remoteConfig.strings[key];
    }
}
