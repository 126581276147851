import {NgModule} from '@angular/core';
import {GetWeekdayPipe} from '../pipes/date-time-pipes';
import {VehiclePipe, VehiclesPipe} from '../pipes/vehicle-pipe';
import {IonicModule} from '@ionic/angular';
import {MomentModule} from 'ngx-moment';
import {CommonModule} from '@angular/common';
import {GridButtonsComponent} from '../grid-buttons/grid-buttons.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ItemsListComponent} from '../items-list/items-list.component';
import {NgArrayPipesModule, NgObjectPipesModule, NgPipesModule} from 'ngx-pipes';
import {GbArrayPipesModule} from "./array-pipes.module";

@NgModule({
    imports: [
        IonicModule,
        MomentModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgArrayPipesModule,
        GbArrayPipesModule,
        NgObjectPipesModule,
        NgPipesModule
    ],
    declarations: [
        GetWeekdayPipe, VehiclePipe, VehiclesPipe,
        GridButtonsComponent, ItemsListComponent,
        ],
    exports: [GetWeekdayPipe, VehiclePipe, VehiclesPipe,
        GridButtonsComponent, ItemsListComponent,
        ]
})
export class WashAvailabilitiesModule { }
